<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                经典案例
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/case/banner-bg.jpg" />
            <div class="mask banner">
                <h3>SUCCESSFUL CASE</h3>
                <h1>用最契合的传播方式，让每一个案例都成为经典</h1>

                <p>
                    We are committed to make classic cases with our tailored communication solutions
                </p>
            </div>
        </div>
        <div class="col-wrap case">
            <img class="case-tit" src="@/assets/mobile/home/case-title.png" />
            <p style="font-size:12px;color:#666; margin:0 20px 20px 20px;">用最契合的传播方式， 让每一个案例都成为经典</p>
            <ul>
                <li :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">汽车类</li>
                <li :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">非汽车类</li>
            </ul>
            <ul class="list" v-if="tabIdx == 1">
                <template v-if="dataList[0] != null">
                    <li v-for="item in dataList[0].list" :key="item.id">
                        <a :href="'/m/case/detail?id=' + item.id">
                            <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_388,w_690'" />
                            <h3>{{ item.title }}</h3>
                            <p class="line2">{{ item.desc }}</p>
                        </a>
                    </li>
                </template>
            </ul>
            <ul class="list" v-if="tabIdx == 2">
                <template v-if="dataList[1] != null">
                    <li v-for="item in dataList[1].list" :key="item.id">
                        <a :href="'/m/case/detail?id=' + item.id">
                            <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_388,w_690'" />
                            <h3>{{ item.title }}</h3>
                            <p class="line2">{{ item.desc }}</p>
                        </a>
                    </li>
                </template>
            </ul>
            <div v-if="dataList[tabIdx - 1] && !dataList[tabIdx - 1].ended" class="blue-btn" :class="loading ? 'disabled' : ''" @click="loadData"><i v-if="loading" class="iconfont icon-loading"></i>查看更多案例+</div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import settings from "@/settings";
export default {
    name: "mobile-case",
    components: { Header, Footer },
    data() {
        return {
            loading: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            tabIdx: 1,
            dataList: [],
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
            var data = this.dataList[this.tabIdx - 1];
            if (!data || (!data.ended && data.list.length == 0)) {
                this.loadData();
            }
        },
        loadData() {
            var data = this.dataList[this.tabIdx - 1];
            if (!data) {
                data = {
                    page: 0,
                    ended: false,
                    list: [],
                };
                this.dataList[this.tabIdx - 1] = data;
            }
            this.loading = true;
            this.$http
                .get(`/api/case/list/${this.tabIdx}?page=${data.page + 1}`)
                .then((res) => {
                    data.page++;
                    data.list = data.list.concat(res.data);
                    data.ended = res.data.length < 9;
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
.blue-btn {
    margin-bottom: 60px;
}
.case .list {
    margin: 20px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    border: none;
}
.list li {
    border-bottom: 1px #ccc solid;
    margin-bottom: 10px;
}
.list li img {
    border-radius: 10px;
}
.list li h3 {
    text-align: left;
    color: #333;
    font-size: 15px;
    margin: 10px 0;
}
.list li p {
    text-align: left;
    color: #666;
    font-size: 13px;
    margin: 10px 0;
}
.case ul {
    margin: 0 15px;
    display: flex;
    border-bottom: 1px #adadad solid;
}
.case ul li {
    flex: 1;
    padding: 15px 0;
    font-size: 15px;
    color: #666666;
}
.case ul .active {
    color: #0048b1;
    border-bottom: 3px #0048b1 solid;
}

.case-tit {
    width: 78%;
    margin: 20px 0 0 0;
}
.banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 15px;
    margin: 20px 15px;
}
.banner h3 {
    font-size: 20px;
}
.banner p {
    font-size: 12px;
    margin: 0 15px;
}
</style>
